@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animate-text {
    background-size: 200% 200%;
    animation: gradient-animation 5s ease infinite;
  }
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
